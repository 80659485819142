@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.loading-icon .icon {
    animation: spin 1s infinite linear;
}




